.PresetDateRangePicker_panel {
  padding: 0 22px 11px
}
.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #00a699;
  color: #00a699;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer
}
.PresetDateRangePicker_button:active {
  outline: 0
}
.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #00a699
}
.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff
}
.SingleDatePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb
}
.SingleDatePickerInput__rtl {
  direction: rtl
}
.SingleDatePickerInput__disabled {
  background-color: #f2f2f2
}
.SingleDatePickerInput__block {
  display: block
}
.SingleDatePickerInput__showClearDate {
  padding-right: 30px
}
.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%
}
.SingleDatePickerInput_clearDate__small {
  padding: 6px
}
.SingleDatePickerInput_clearDate__hide {
  visibility: hidden
}
.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.SingleDatePickerInput_clearDate_svg__small {
  height: 9px
}
.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.SingleDatePicker {
  position: relative;
  display: inline-block
}
.SingleDatePicker__block {
  display: block
}
.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}
.SingleDatePicker_picker__rtl {
  direction: rtl
}
.SingleDatePicker_picker__directionLeft {
  left: 0
}
.SingleDatePicker_picker__directionRight {
  right: 0
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}
.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff
}
.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}
.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0
}
.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2
}
.DayPickerKeyboardShortcuts_show::before {
  content: "";
  display: block;
  position: absolute
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid #00a699;
  bottom: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__topRight::before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #00a699;
  top: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0
}
.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #00a699;
  top: 0;
  left: 0
}
.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid #008489
}
.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px
}
.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px
}
.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px
}
.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left
}
.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0
}
.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2
}
.DayPickerKeyboardShortcuts_close:active {
  outline: 0
}
.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a
}
.CalendarDay {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center
}
.CalendarDay:active {
  outline: 0
}
.CalendarDay__defaultCursor {
  cursor: default
}
.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #484848;
  background: #fff
}
.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: inherit
}
.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit
}
.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848
}
.CalendarDay__outside:hover {
  border: 0
}
.CalendarDay__blocked_minimum_nights {
  background: #fff;
  border: 1px solid #eceeee;
  color: #cacccd
}
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd
}
.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #484848
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848
}
.CalendarDay__selected_span {
  background: #66e2da;
  border: 1px double #33dacd;
  color: #fff
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  border: 1px double #33dacd;
  color: #fff
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  border: 1px double #00a699;
  color: #fff
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2f1ec;
  border: 1px double #80e8e0;
  color: #007a87
}
.CalendarDay__hovered_span:active {
  background: #80e8e0;
  border: 1px double #80e8e0;
  color: #007a87
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd
}
.CalendarDay__hovered_start_first_possible_end {
  background: #eceeee;
  border: 1px double #eceeee
}
.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  border: 1px double #e4e7e7
}
.CalendarMonth {
  background: #fff;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  user-select: none
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0
}
.CalendarMonth_verticalSpacing {
  border-collapse: separate
}
.CalendarMonth_caption {
  color: #484848;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: initial
}
.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px
}
.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0
}
.CalendarMonthGrid__animating {
  z-index: 1
}
.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px
}
.CalendarMonthGrid__vertical {
  margin: 0 auto
}
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%
}
.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none
}
.CalendarMonthGrid_month__hidden {
  visibility: hidden
}
.DayPickerNavigation {
  position: relative;
  z-index: 2
}
.DayPickerNavigation__horizontal {
  height: 0
}
.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0
}
.DayPickerNavigation__verticalScrollableDefault {
  position: relative
}
.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0
}
.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4
}
.DayPickerNavigation_button__default:active {
  background: #f2f2f2
}
.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #f2f2f2
}
.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: 1px solid #f2f2f2
}
.DayPickerNavigation_button__disabled:active {
  background: 0 0
}
.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: .78;
  border-radius: 3px;
  padding: 6px 9px
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px
}
.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%
}
.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0
}
.DayPickerNavigation_nextButton__verticalScrollableDefault {
  width: 100%
}
.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block
}
.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848
}
.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2
}
.DayPicker {
  background: #fff;
  position: relative;
  text-align: left
}
.DayPicker__horizontal {
  background: #fff
}
.DayPicker__verticalScrollable {
  height: 100%
}
.DayPicker__hidden {
  visibility: hidden
}
.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0,0,0,.05),0 0 0 1px rgba(0,0,0,.07);
  border-radius: 3px
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%
}
.DayPicker_portal__vertical {
  position: initial
}
.DayPicker_focusRegion {
  outline: 0
}
.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top
}
.DayPicker_weekHeaders {
  position: relative
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px
}
.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 62px;
  z-index: 2;
  text-align: left
}
.DayPicker_weekHeader__vertical {
  left: 50%
}
.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center
}
.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center
}
.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px
}
.DayPicker_transitionContainer__horizontal {
  transition: height .2s ease-in-out
}
.DayPicker_transitionContainer__vertical {
  width: 100%
}
.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll
}
.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle
}
.DateInput__small {
  width: 97px
}
.DateInput__block {
  width: 100%
}
.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb
}
.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0
}
.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: .2px;
  padding: 7px 7px 5px
}
.DateInput_input__regular {
  font-weight: auto
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  user-select: none
}
.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #008489;
  border-left: 0
}
.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic
}
.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}
.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2
}
.DateInput_fangShape {
  fill: #fff
}
.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent
}
.DateRangePickerInput {
  background-color: #fff;
  display: inline-block
}
.DateRangePickerInput__disabled {
  background: #f2f2f2
}
.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb
}
.DateRangePickerInput__rtl {
  direction: rtl
}
.DateRangePickerInput__block {
  display: block
}
.DateRangePickerInput__showClearDates {
  padding-right: 30px
}
.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848
}
.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px
}
.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}
.DateRangePickerInput_clearDates__small {
  padding: 6px
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%
}
.DateRangePickerInput_clearDates__hide {
  visibility: hidden
}
.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.DateRangePickerInput_clearDates_svg__small {
  height: 9px
}
.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}
.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.DateRangePicker {
  position: relative;
  display: inline-block
}
.DateRangePicker__block {
  display: block
}
.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}
.DateRangePicker_picker__rtl {
  direction: rtl
}
.DateRangePicker_picker__directionLeft {
  left: 0
}
.DateRangePicker_picker__directionRight {
  right: 0
}
.DateRangePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}
.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff
}
.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}
.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb;
}
.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  touch-action: pan-x;
}
.rc-slider-handle:focus {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: #57c5f7;
}
.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-dot-reverse {
  margin-left: 0;
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
          animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
          animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
          animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
          animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  -webkit-animation-name: rcToolTipZoomIn;
          animation-name: rcToolTipZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  -webkit-animation-name: rcToolTipZoomOut;
          animation-name: rcToolTipZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.9;
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}
.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}
.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}
.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}
.rc-tooltip-inner {
  padding: 8px 10px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: #373737;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
}
.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #373737;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #373737;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #373737;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #373737;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

.rc-color-picker-panel-inner {
  position: relative;
  border-radius: 4px;
  box-shadow: 0 1px 5px #ccc;
  border: 1px solid #ccc;
  padding-bottom: 8px;
}
.rc-color-picker-panel-wrap {
  margin: 5px 0 0;
  height: 30px;
  width: 100%;
  position: relative;
}
.rc-color-picker-panel-wrap-preview {
  position: absolute;
  right: 8px;
}
.rc-color-picker-panel-wrap-ribbon {
  position: absolute;
  left: 8px;
  top: 0;
  right: 43px;
  height: 30px;
}
.rc-color-picker-panel-wrap-alpha {
  position: absolute;
  left: 8px;
  right: 43px;
  bottom: 0;
  height: 12.5px;
}
.rc-color-picker-panel-wrap-has-alpha .rc-color-picker-panel-wrap-ribbon {
  height: 12.5px;
}
.rc-color-picker-trigger {
  border: 1px solid #999;
  display: inline-block;
  padding: 2px;
  border-radius: 2px;
  -webkit-user-select: none;
          user-select: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
  box-shadow: 0 0 0 2px #fff inset;
}
.rc-color-picker-trigger-open {
  box-shadow: 0px 0px 3px #999;
}
.rc-color-picker-panel {
  width: 218px;
  background-color: #fff;
  box-sizing: border-box;
  outline: none;
  z-index: 9;
  border-radius: 4px;
  -webkit-user-select: none;
  user-select: none;
}
.rc-color-picker-panel * {
  box-sizing: border-box;
}
.rc-color-picker-panel-open {
  display: block;
}
.rc-color-picker-panel-close {
  display: none;
}
.rc-color-picker-panel-preview {
  height: 30px;
  width: 30px;
  overflow: hidden;
  border-radius: 2px;
  background-image: url('data:image/png;base64,R0lGODdhCgAKAPAAAOXl5f///ywAAAAACgAKAEACEIQdqXt9GxyETrI279OIgwIAOw==');
}
.rc-color-picker-panel-preview span {
  box-shadow: 0 0 2px #808080 inset;
}
.rc-color-picker-panel-preview span,
.rc-color-picker-panel-preview input[type=color] {
  position: absolute;
  display: block;
  height: 100%;
  width: 30px;
  border-radius: 2px;
}
.rc-color-picker-panel-preview input[type=color] {
  opacity: 0;
}
.rc-color-picker-panel-board {
  position: relative;
  font-size: 0;
  -webkit-user-select: none;
          user-select: none;
  margin: 8px 8px 0px;
}
.rc-color-picker-panel-board span {
  position: absolute;
  border-radius: 10px;
  border: 1px solid #fff;
  width: 9px;
  height: 9px;
  margin: -4px 0 0 -4px;
  left: -999px;
  top: -999px;
  box-shadow: 0 0 1px rgba(120, 120, 120, 0.7);
  z-index: 2;
}
.rc-color-picker-panel-board-hsv {
  width: 200px;
  height: 150px;
  position: relative;
  z-index: 1;
  border-radius: 2px;
}
.rc-color-picker-panel-board-value {
  border-radius: 2px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9InJnYigwLDAsMCkiIHN0b3Atb3BhY2l0eT0iMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
  background-image: linear-gradient(to bottom, transparent 0%, #000000 100%);
}
.rc-color-picker-panel-board-saturation {
  border-radius: 2px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0icmdiKDAsMCwwKSIgc3RvcC1vcGFjaXR5PSIwIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
  background-image: linear-gradient(to right, #ffffff 0%, transparent 100%);
}
.rc-color-picker-panel-board-handler {
  box-shadow: 0 0 2px #808080 inset;
  border-radius: 2px;
  cursor: crosshair;
  -webkit-user-select: none;
          user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.rc-color-picker-panel-ribbon {
  position: relative;
  height: 100%;
  border-radius: 2px;
  box-shadow: 0 0 2px #808080 inset;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmZjAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAlIiBzdG9wLWNvbG9yPSIjZmY5OTAwIiBzdG9wLW9wYWNpdHk9IjEiLz48c3RvcCBvZmZzZXQ9IjIwJSIgc3RvcC1jb2xvcj0iI2NkZmYwMCIgc3RvcC1vcGFjaXR5PSIxIi8+PHN0b3Agb2Zmc2V0PSIzMCUiIHN0b3AtY29sb3I9IiMzNWZmMDAiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iNDAlIiBzdG9wLWNvbG9yPSIjMDBmZjY2IiBzdG9wLW9wYWNpdHk9IjEiLz48c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzAwZmZmZCIgc3RvcC1vcGFjaXR5PSIxIi8+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiMwMDY2ZmYiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iNzAlIiBzdG9wLWNvbG9yPSIjMzIwMGZmIiBzdG9wLW9wYWNpdHk9IjEiLz48c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iI2NkMDBmZiIgc3RvcC1vcGFjaXR5PSIxIi8+PHN0b3Agb2Zmc2V0PSI5MCUiIHN0b3AtY29sb3I9IiNmZjAwOTkiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
  background-image: linear-gradient(to right, #ff0000 0%, #ff9900 10%, #cdff00 20%, #35ff00 30%, #00ff66 40%, #00fffd 50%, #0066ff 60%, #3200ff 70%, #cd00ff 80%, #ff0099 90%, #ff0000 100%);
}
.rc-color-picker-panel-ribbon span {
  position: absolute;
  top: 0;
  height: 100%;
  width: 4px;
  border: 1px solid #000000;
  padding: 1px 0;
  margin-left: -2px;
  background-color: #fff;
  border-radius: 3px;
}
.rc-color-picker-panel-ribbon-handler {
  position: absolute;
  width: 104%;
  height: 100%;
  left: -2%;
  cursor: pointer;
}
.rc-color-picker-panel-alpha {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  background-image: url('data:image/png;base64,R0lGODdhCgAKAPAAAOXl5f///ywAAAAACgAKAEACEIQdqXt9GxyETrI279OIgwIAOw==');
  background-repeat: repeat;
  -webkit-user-select: none;
          user-select: none;
}
.rc-color-picker-panel-alpha-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  box-shadow: 0 0 2px #808080 inset;
}
.rc-color-picker-panel-alpha span {
  position: absolute;
  top: 0;
  height: 100%;
  width: 4px;
  border: 1px solid #000000;
  padding: 1px 0;
  margin-left: -2px;
  background-color: #fff;
  border-radius: 3px;
}
.rc-color-picker-panel-alpha-handler {
  position: absolute;
  width: 104%;
  height: 100%;
  left: -2%;
  cursor: pointer;
}
.rc-color-picker-panel-params {
  font-size: 12px;
}
.rc-color-picker-panel-params-input {
  overflow: hidden;
  padding: 2px 8px;
}
.rc-color-picker-panel-params input {
  -webkit-user-select: text;
          user-select: text;
  text-align: center;
  padding: 1px;
  margin: 0;
  float: left;
  border-radius: 2px;
  border: 1px solid #CACACA;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
}
.rc-color-picker-panel-params-hex {
  width: 52px;
}
.rc-color-picker-panel-params input[type=number] {
  margin-left: 5px;
  width: 44px;
}
.rc-color-picker-panel-params input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.rc-color-picker-panel-params-lable {
  padding: 2px 8px;
  height: 22px;
  line-height: 18px;
  -webkit-user-select: none;
          user-select: none;
}
.rc-color-picker-panel-params-lable label {
  float: left;
  text-align: center;
}
.rc-color-picker-panel-params-lable-hex {
  width: 52px;
}
.rc-color-picker-panel-params-lable-number,
.rc-color-picker-panel-params-lable-alpha {
  margin-left: 5px;
  width: 44px;
  text-transform: uppercase;
}
.rc-color-picker-panel-params-lable-number:hover {
  border-radius: 2px;
  background-color: #eee;
  box-shadow: 0 0 0 1px #ccc inset;
  cursor: pointer;
}
.rc-color-picker-panel-params-has-alpha input[type=number] {
  width: 32px;
}
.rc-color-picker-panel-params-has-alpha .rc-color-picker-panel-params-lable-number,
.rc-color-picker-panel-params-has-alpha .rc-color-picker-panel-params-lable-alpha {
  width: 32px;
}
.rc-color-picker {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 1000;
}
.rc-color-picker-wrap {
  display: inline-block;
}
.rc-color-picker-slide-up-enter {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  display: block !important;
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-color-picker-slide-up-appear {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  display: block !important;
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-color-picker-slide-up-leave {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  display: block !important;
  opacity: 1;
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-color-picker-slide-up-enter.rc-color-picker-slide-up-enter-active.rc-color-picker-placement-bottomLeft,
.rc-color-picker-slide-up-enter.rc-color-picker-slide-up-enter-active.rc-color-picker-placement-bottomRight,
.rc-color-picker-slide-up-appear.rc-color-picker-slide-up-appear-active.rc-color-picker-placement-bottomLeft,
.rc-color-picker-slide-up-appear.rc-color-picker-slide-up-appear-active.rc-color-picker-placement-bottomRight {
  -webkit-animation-name: rcColorPickerSlideUpIn;
          animation-name: rcColorPickerSlideUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-color-picker-slide-up-enter.rc-color-picker-slide-up-enter-active.rc-color-picker-placement-topLeft,
.rc-color-picker-slide-up-enter.rc-color-picker-slide-up-enter-active.rc-color-picker-placement-topRight,
.rc-color-picker-slide-up-appear.rc-color-picker-slide-up-appear-active.rc-color-picker-placement-topLeft,
.rc-color-picker-slide-up-appear.rc-color-picker-slide-up-appear-active.rc-color-picker-placement-topRight {
  -webkit-animation-name: rcColorPickerSlideDownIn;
          animation-name: rcColorPickerSlideDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-color-picker-slide-up-leave.rc-color-picker-slide-up-leave-active.rc-color-picker-placement-bottomLeft,
.rc-color-picker-slide-up-leave.rc-color-picker-slide-up-leave-active.rc-color-picker-placement-bottomRight {
  -webkit-animation-name: rcColorPickerSlideUpOut;
          animation-name: rcColorPickerSlideUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-color-picker-slide-up-leave.rc-color-picker-slide-up-leave-active.rc-color-picker-placement-topLeft,
.rc-color-picker-slide-up-leave.rc-color-picker-slide-up-leave-active.rc-color-picker-placement-topRight {
  -webkit-animation-name: rcColorPickerSlideDownOut;
          animation-name: rcColorPickerSlideDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rcColorPickerSlideUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes rcColorPickerSlideUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes rcColorPickerSlideUpOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}
@keyframes rcColorPickerSlideUpOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}
@-webkit-keyframes rcColorPickerSlideDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes rcColorPickerSlideDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes rcColorPickerSlideDownOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}
@keyframes rcColorPickerSlideDownOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}

